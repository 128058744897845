.campaign-over-page {
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 0 5rem;
}

.campaign-over-page h1 {
    font-size: clamp(1.5rem, 4vw, 4rem);
    color: white;
    text-align: center;
    line-height: 110%;
}

.swipe-survey-page {
    width: 100%;
    height: 90%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.swipe-survey-page canvas {
    filter: blur(2px);
}

.swipe-survey-page .swiper-container {
    width: 80%;

    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.swipe-survey-page .swiper-container .tinder-cards {
    width: 600px;
    height: 700px;

    position: relative;
}

.swipe-survey-page .swiper-container .tinder-cards .tinder-card {
    position: absolute;

    width: 100%;
    height: 100%;

    background-color: #001b71;
    border-right: 10px solid #ff37be;
    border-bottom: 1px solid #ff37be;

    box-sizing: border-box;
    padding: 30px 50px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.swipe-survey-page .swiper-container .tinder-cards .tinder-card h1 {
    font-family: 'NeueHaasUnicaPro', sans-serif;
    text-align: left;
    font-size: 60px;
    font-weight: 800;
    line-height: 110%;
    margin: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    color: white;
}

.swipe-survey-page .swiper-container .tinder-cards .tinder-card p {
    font-family: 'NeueHaasUnicaPro', sans-serif;
    color: white;
    font-size: 25px;
}

.swipe-survey-page .swiper-container .tinder-cards .tinder-card .progress-bar {
    width: 350px;
    min-height: 10px;
    background-color: white;
    border-radius: 30px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.swipe-survey-page
    .swiper-container
    .tinder-cards
    .tinder-card
    .progress-bar
    .active-progress {
    width: 50%;
    height: 100%;
    background-color: #ff37be;
    border-radius: 30px;
}

.swipe-survey-page .answer-button {
    width: 142px;
    height: 142px;
    box-sizing: border-box;

    border: 2px solid #ff37be;
    border-radius: 100%;

    background-color: #001b71;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 2rem;
}

.swipe-survey-page .swiper-container .swiper-buttons {
    display: none;
}

@media screen and (max-width: 1280px) {
    .swiper-container {
        width: 500px !important;

        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center;
        gap: 1rem;
    }

    .swipe-survey-page .swiper-container .tinder-cards {
        width: 100%;
        height: 450px;
    }

    .swipe-survey-page .swiper-container .tinder-cards .tinder-card {
        width: 100%;
        height: 100%;

        padding: 2rem 1.5rem;
    }

    .swipe-survey-page .swiper-container .tinder-cards .tinder-card h1 {
        font-size: 2.75rem;
    }

    .swipe-survey-page .swiper-container .tinder-cards .tinder-card .progress-bar {
        width: 80%;
    }

    .swipe-survey-page .swiper-container .answer-button-wrapper {
        display: none;
    }

    .swipe-survey-page .swiper-container .swiper-buttons {
        display: flex;

        width: 100%;
        height: 25%;
        padding: 0;

        justify-content: center;
        align-items: center;
        gap: 6rem;
    }

    .swipe-survey-page .swiper-container .swiper-buttons button {
        scale: 0.6;
    }
}

@media screen and (max-width: 1024px) {
    .swiper-container {
        width: 500px !important;

        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center;
        gap: 1rem;
    }

    .swipe-survey-page .swiper-container .tinder-cards {
        width: 100%;
        height: 450px;
    }

    .swipe-survey-page .swiper-container .tinder-cards .tinder-card {
        width: 100%;
        height: 100%;

        padding: 2rem 1.5rem;
    }

    .swipe-survey-page .swiper-container .tinder-cards .tinder-card h1 {
        font-size: 2.75rem;
    }

    .swipe-survey-page .swiper-container .tinder-cards .tinder-card .progress-bar {
        width: 80%;
    }

    .swipe-survey-page .swiper-container .answer-button-wrapper {
        display: none;
    }

    .swipe-survey-page .swiper-container .swiper-buttons {
        display: flex;

        width: 100%;
        height: 25%;
        padding: 0;

        justify-content: center;
        align-items: center;
        gap: 6rem;
    }

    .swipe-survey-page .swiper-container .swiper-buttons button {
        scale: 0.6;
    }
}

@media screen and (max-width: 900px) {
}

@media screen and (max-width: 540px) {
    .swiper-container {
        /* width: 340px !important; */

        width: 70% !important;

        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center;
        gap: 1rem;
    }

    .swipe-survey-page .swiper-container .tinder-cards {
        width: 100%;
        height: 450px;
    }

    .swipe-survey-page .swiper-container .tinder-cards .tinder-card {
        width: 100%;
        height: 100%;

        padding: 2rem 1.5rem;
    }

    .swipe-survey-page .swiper-container .tinder-cards .tinder-card h1 {
        font-size: 2rem;
    }

    .swipe-survey-page .swiper-container .tinder-cards .tinder-card .progress-bar {
        width: 80%;
    }

    .swipe-survey-page .swiper-container .answer-button-wrapper {
        display: none;
    }

    .swipe-survey-page .swiper-container .swiper-buttons {
        display: flex;

        width: 100%;
        height: 15%;
        padding: 0;

        justify-content: center;
        align-items: center;
    }

    .swipe-survey-page .swiper-container .swiper-buttons button {
        scale: 0.5;
    }
}

@media screen and (max-width: 430px) {
    .swipe-survey-page .swiper-container .swiper-buttons {
        display: flex;

        width: 100%;
        height: 25%;
        padding: 0;

        justify-content: center;
        align-items: center;
        gap: 3rem;
    }
}

@media screen and (max-width: 375px) {
    .swiper-container {
        /* width: 250px !important; */
    }

    .swipe-survey-page .swiper-container .tinder-cards .tinder-card h1 {
        font-size: 1.75rem;
    }

    .swipe-survey-page .swiper-container .swiper-buttons {
        display: flex;

        width: 100%;
        height: 20%;
        padding: 0;

        justify-content: center;
        align-items: center;
        gap: 2rem;
    }
}

@media screen and (max-width: 320px) {
    .swiper-container {
        /* width: 275px !important; */
    }

    .swipe-survey-page .swiper-container .swiper-buttons {
        display: flex;

        width: 100%;
        height: 20%;
        padding: 0;

        justify-content: center;
        align-items: center;
        gap: 0rem;
    }
}

@media screen and (max-width: 280px) {
    .swiper-container {
        /* width: 235px !important; */
    }

    .swipe-survey-page .swiper-container .tinder-cards .tinder-card h1 {
        font-size: 1.25rem !important;
    }

    .swipe-survey-page .swiper-container .tinder-cards .tinder-card div p {
        font-size: 1.5rem !important;
    }
}

@media screen and (max-height: 740px) {
    .swipe-survey-page .swiper-container .tinder-cards {
        height: 450px;
    }

    .swipe-survey-page .swiper-container .tinder-cards .tinder-card h1 {
        font-family: 'NeueHaasUnicaPro', sans-serif;
        color: white;
        font-size: 1.75rem;
    }
}

@media screen and (max-height: 700px) {
    .swipe-survey-page .swiper-container .tinder-cards {
        height: 390px;
    }

    .swipe-survey-page .swiper-container .tinder-cards .tinder-card h1 {
        font-family: 'NeueHaasUnicaPro', sans-serif;
        color: white;
        font-size: 1.75rem;
    }
}

@media screen and (max-height: 800px) {
    .swipe-survey-page .swiper-container .tinder-cards {
        height: 320px;
    }

    .swipe-survey-page .swiper-container .swiper-buttons {
        display: flex;

        width: 100%;
        height: 115px;
        padding: 0;

        justify-content: center;
        align-items: center;
        gap: 0rem;
    }

    .swipe-survey-page .swiper-container .swiper-buttons .answer-button {
        scale: 0.7;
    }
}

.modal-window {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 50;

    width: 800px;
    height: 600px;
}

.modal-panel {
    position: relative;

    height: 100%;
    width: 100%;

    background-color: rgba(255, 255, 255, 0.98);

    border-radius: 1.5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-panel p {
    font-family: 'NeueHaasUnicaPro', sans-serif;
    font-size: 30px;
    font-weight: 700;

    width: 80%;

    line-height: 150%;
}

.modal-panel button {
    scale: 2;

    position: absolute;
    right: 8%;
    top: 8%;
}

.modal-panel button:focus {
    outline: none;
}

@media screen and (max-width: 540px) {
    .modal-window {
        width: 370px;
        height: 500px;
    }

    .modal-window .modal-panel p {
        font-size: 1.75rem;
    }
}

@media screen and (max-width: 430px) {
    .modal-window {
        width: 390px;
        height: 500px;
    }

    .modal-window .modal-panel p {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 375px) {
    .modal-window {
        width: 335px;
        height: 500px;
    }

    .modal-window .modal-panel p {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 320px) {
    .modal-window {
        width: 280px;
        height: 500px;
    }

    .modal-window .modal-panel p {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 280px) {
    .modal-window {
        width: 250px;
        height: 500px;
    }

    .modal-window .modal-panel p {
        font-size: 1.4rem;
    }
}

@media screen and (max-height: 700px) {
    .modal-window {
        height: 500px;
    }
}

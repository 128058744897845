.gift-page {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.gift-page canvas {
    filter: blur(2px);
    z-index: 0;
}

/* LAYERED BUTTON */

.gift-page .button-container {
    width: 250px;
    height: 50px;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;

    position: absolute;
    top: 20%;
    left: 0;
}

.gift-page .button-container .button-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.5s ease;
    color: white;
    z-index: 5;
    font-size: 1.5rem;
}

.gift-page .layer {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #007bff;
    top: 0;
    left: 0;
}

.gift-page .layer:nth-child(1) {
    max-width: 0%;
    z-index: 1;
    background-color: #ef19c1;
}

.gift-page .layer:nth-child(2) {
    max-width: 0%;
    background-color: #6974ac;
    z-index: 2;
}

.gift-page .layer:nth-child(3) {
    max-width: 0%;
    background-color: #36458f;
    z-index: 3;
}

.gift-page .layer:nth-child(4) {
    max-width: 0%;
    background-color: #051874;
    transition: background-color 0.1s ease;
    color: white;
    z-index: 4;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.5rem;

    color: rgba(255, 255, 255, 0);
}

.gift-page .layer:nth-child(4):hover {
    background-color: #005acb;
    transition: background-color 0.1s ease;
}

.gift-page .button-container.next-btn {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 400px;
    height: 90px;
}

.gift-page .button-container.next-btn .layer {
    width: 100%;
    height: 100%;
}

.gift-page .button-container.next-btn .next-button-text {
    position: absolute;
    top: 0%;
    left: 0%;
    translate: translate(-0%, -0%);
    z-index: 4;
    color: white;

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 2.5rem;
}

.gift-page .button-container.next-btn .next-button-text img {
    position: absolute;
    top: 37.5%;
    left: 60%;
    translate: translate(-50%, -50%);
}

.button-container.thanks-btn {
    position: absolute;
    top: 80%;
    left: auto;
    right: 0;
    transform: scaleX(-1);

    width: 400px;
    height: 70px;
}

.button-container.thanks-btn .layer {
    /* width: 100%;
    height: 100%;
    max-width: 0%; */
}

.button-container.thanks-btn .button-text {
    transform: translate(-50%, -50%) scaleX(-1);
}

.button-container.thanks-btn .layer {
    cursor: default;
}

.button-container.thanks-btn .layer:nth-child(4):hover {
    cursor: default;
    background-color: #051874;
}

.reward-amount {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;

    margin: 0;

    color: white;
    font-size: clamp(5rem, 8vw, 13rem);
    text-shadow: 0px 0px 100px #000;

    transform-origin: center;

    line-height: 110%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: clamp(0.5rem, 1vw, 1rem);
}

@media screen and (max-width: 1440px) {
    .gift-page .button-container {
        width: 200px;
        height: 40px;
    }

    .gift-page .button-container .button-text {
        font-size: 1.2rem;
    }

    .gift-page .button-container.next-btn {
        width: 300px;
        height: 70px;
    }

    .gift-page .button-container.next-btn .next-button-text {
        font-size: 2rem;
    }

    .gift-page .button-container.next-btn .next-button-text img {
        top: 32.5%;
        left: 60%;
    }

    .button-container.thanks-btn {
        width: 300px;
        height: 60px;
    }
}

@media screen and (max-width: 1024px) {
    .gift-page .button-container {
        width: 300px;
        height: 60px;
    }

    .gift-page .button-container .button-text {
        font-size: 1.5rem;
    }

    .gift-page .button-container.next-btn {
        width: 250px;
        height: 50px;
        top: 40%;
        transform: translate(-50%, -50%);
    }

    .gift-page .button-container.next-btn .next-button-text {
        font-size: 1.5rem;
    }

    .gift-page .button-container.next-btn .next-button-text img {
        top: 25%;
        left: 60%;
        scale: 0.7;
    }

    .button-container.thanks-btn {
        width: 250px;
        height: 50px;
    }
}

@media screen and (max-width: 540px) {
    .gift-page .button-container {
        width: 300px;
        height: 60px;
    }

    .gift-page .button-container .button-text {
        font-size: 1.5rem;
    }

    .gift-page .button-container.next-btn {
        width: 200px;
        height: 40px;
    }

    .gift-page .button-container.next-btn .next-button-text {
        font-size: 1.2rem;
    }

    .gift-page .button-container.next-btn .next-button-text img {
        top: 20%;
        left: 60%;
        scale: 0.6;
    }

    .button-container.thanks-btn {
        width: 200px;
        height: 40px;
    }
}

@media screen and (max-width: 375px) {
    .gift-page .button-container {
        width: 70%;
        height: 40px;
    }
}

.customButton {
    padding: 0.7rem 2.2rem;
    margin: 0.8rem;
    background-color: #001B71;
    color: white;
    border-radius: 100px;

    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    text-align: center;
    font-size: 1rem;
}

/*@media screen and(min-width: 900px) {*/
/*    .customButton{*/
/*        font-size: 2rem;*/
/*        padding: 100px;*/
/*    }*/

/*}*/

.customButton:hover:enabled {
    background-color: #213e9d;
}

.largeButton{
    padding: 2rem;
    border: 1px solid var(--color-2);
    width: 100%;
    margin: 10px 0px;

    box-shadow: 0px 2px 5px 2px rgba(66, 68, 90, 0.21);

    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    text-align: center;
    font-size: 1rem;
}
.largeButton:enabled:hover{
    /*background-color: #c6e3ff;*/
}
.largeButton:disabled{
    background-color: #ff6e6e;
}

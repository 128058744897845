.header {
    width: 100%;

    box-sizing: border-box;

    padding: 1rem 2rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header h1 {
    color: white;
    font-size: 3rem;
}

.header .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header .logo img {
    width: 100%;
}

@media screen and (max-height: 800px) {
    .header h1 {
        font-size: 2.5rem;
    }

    .header .logo {
        width: 20%;
    }
}

@media screen and (max-width: 1024px) {
    .header h1 {
        font-size: 2.5rem;
    }

    .header .logo {
        width: 30%;
    }
}

@media screen and (max-width: 540px) {
    .header {
        padding: 1.25rem 1.75rem;
    }

    .header .logo {
        width: 40%;
    }

    .header h1 {
        font-size: 1.75rem;

        margin: 0;
    }
}

@media screen and (max-width: 375px) {
    .header {
        padding: 1.25rem 1.5rem;
    }

    .header .logo {
        width: 37.5%;
    }

    .header h1 {
        font-size: 1.75rem;
    }
}

.wheel-container {
    position: relative;
    width: 100%;
    max-height: 55vh;
    max-width: 55vh;
    margin: 0 auto;
    overflow: hidden;
}

.wheel {
    width: 100%;
    height: 100%;
    transition: transform 5s cubic-bezier(0, 0.99, 0.2, 0.99);
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}


.wheel-container img {
    display: block;
}

.pointer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.con {
    background-size: cover;
    background-position: center;
}

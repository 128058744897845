.footer {
    position: relative;

    width: 100%;

    padding: 0.5rem 2rem;

    box-sizing: border-box;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer .footer-buttons {
    display: flex;
    gap: 0.75rem;
}

.footer .footer-buttons button {
    color: white;
    font-size: 1.5rem;
}

.footer .footer-buttons .desktop-tscs {
    display: block;
}

.footer .footer-buttons .mobile-tscs {
    display: none;
}

.footer.with-santa .little-santa {
    position: absolute;
    bottom: -1000%;
    left: 67.5%;
    /* transform: translate(-50%, -50%); */
    z-index: 6;

    width: clamp(200px, 30vw, 300px);
}

.footer.with-santa .little-santa img {
    position: relative;

    width: 100%;
}

.footer.with-santa .message-cloud {
    position: absolute;
    bottom: 250%;
    opacity: 0;
    left: 80%;
    /* transform: translate(-50%, -50%); */
    z-index: 6;
}

.footer.with-santa .message-cloud.linked {
}

.footer.with-santa .message-cloud.linked img {
    position: relative;
    width: 100%;
}

.footer.with-santa .message-cloud-text-wrapper {
    width: 260px;
    height: 90px;

    position: absolute;
    left: 87.5%;
    bottom: 320%;
    transform: translateX(-50%);
    z-index: 8;

    padding: 0.5rem;

    box-sizing: border-box;

    display: flex;
    justify-content: center;
    align-items: center;
}

.footer.with-santa .message-cloud-text {
    /* position: absolute;
    height: 110%;
    max-width: 250px;
    bottom: 310%;
    opacity: 0;
    left: 82%;
    z-index: 6; */
    width: 100%;
    height: 100%;

    font-size: 1.5rem;
    line-height: 130%;

    display: inline-block;
}

@media screen and (max-width: 1440px) {
    .footer.with-santa .message-cloud-text-wrapper {
        width: 200px;
        height: 100px;

        position: absolute;
        left: 90.5%;
        bottom: 270%;
        transform: translateX(-50%);
        z-index: 8;

        padding: 1rem;

        box-sizing: border-box;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 1024px) {
    .footer .footer-buttons button {
        font-size: 1rem;
    }

    .footer .logo {
        width: 150px;
        height: 40px;
    }

    .footer .logo img {
        width: 100%;
    }

    .footer.with-santa .little-santa {
        left: 50%;
        transform: translateX(-50%);
    }

    .footer.with-santa .message-cloud {
        width: 270px;
        left: 70%;
        transform: translateX(-50%) scaleX(0.8);
    }

    .footer.with-santa .message-cloud-text-wrapper {
        width: 200px;
        height: 100px;

        position: absolute;
        left: 70%;
        bottom: 300%;
        transform: translateX(-50%);
        z-index: 8;

        padding: 1rem;

        box-sizing: border-box;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .footer.with-santa .message-cloud-text {
        line-height: 120%;
        font-family: 'NeueHaasUnicaPro', sans-serif;
        font-weight: 400;
        font-size: 1.2rem;
        letter-spacing: 0.125rem;
    }
}

@media screen and (max-width: 540px) {
    .footer {
        justify-content: space-between;
    }

    .footer .footer-buttons {
        gap: 0rem;
    }

    .footer .footer-buttons button {
        font-size: 1rem;
        padding: 0 0.5rem;
    }

    .footer .footer-buttons .desktop-tscs {
        display: none;
    }

    .footer .footer-buttons .mobile-tscs {
        display: block;
    }

    .footer .logo {
        width: 130px;
    }

    .footer .logo img {
        width: 100%;
    }

    .footer.with-santa .little-santa {
        left: 50%;
        transform: translateX(-50%);
    }

    .footer.with-santa .message-cloud {
        width: 270px;
        left: 70%;
        transform: translateX(-50%) scaleX(0.8);
    }

    .footer.with-santa .message-cloud-text-wrapper {
        width: 200px;
        height: 100px;

        position: absolute;
        left: 70%;
        bottom: 300%;
        transform: translateX(-50%);
        z-index: 8;

        padding: 1rem;

        box-sizing: border-box;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .footer.with-santa .message-cloud-text {
        line-height: 120%;
        font-family: 'NeueHaasUnicaPro', sans-serif;
        font-weight: 400;
        font-size: 1.2rem;
        letter-spacing: 0.125rem;
    }
}

@media screen and (max-width: 430px) {
    .footer.with-santa .message-cloud-text-wrapper {
        bottom: 300%;
    }
}

@media screen and (max-width: 375px) {
    .footer {
        padding: 0.5rem 1.25rem;
    }

    .footer .footer-buttons {
        gap: 0.25rem;
    }

    .footer .footer-buttons button {
        font-size: 0.8rem;
        padding: 0 0.25rem;
    }

    .footer .logo {
        width: 100px;
    }

    .footer.with-santa .message-cloud {
        width: 270px;
        left: 65%;
        bottom: 180%;
        transform: translateX(-50%) scaleX(0.8) scaleY(0.8);
    }

    /* .footer.with-santa .message-cloud-text-wrapper {
        width: 200px;
        height: 100px;

        position: absolute;
        left: 70%;
        bottom: 320%;
        transform: translateX(-50%);
        z-index: 8;

        padding: 1rem;

        box-sizing: border-box;

        display: flex;
        justify-content: center;
        align-items: center;
    } */

    .footer.with-santa .message-cloud-text-wrapper {
        bottom: 180%;
        left: 65%;
    }

    .footer.with-santa .message-cloud-text {
        /* width: 150px;
        left: 72.5%; */

        line-height: 130%;
        font-family: 'NeueHaasUnicaPro', sans-serif;
        font-weight: 400;
        font-size: 0.9rem;
        letter-spacing: 0.125rem;
        /*
        bottom: 295%;
        transform: translateX(-50%) scaleX(0.8); */
    }
}

@media screen and (max-width: 320px) {
    .footer {
        padding: 0.5rem 1rem;
    }

    .footer .footer-buttons {
        gap: 0.5rem;
    }

    .footer .footer-buttons button {
        font-size: 0.7rem;
    }

    .footer .logo {
        width: 90px;
    }
}

.reward-page {
    position: relative;

    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    gap: 1.25rem;

    padding: 0rem 1rem;
}

.reward-page .reward-wrapper {
    position: absolute;
    height: 65%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    top: 0;
}

.reward-page .title {
    /* position: absolute;
    z-index: 5;
    top: 7.5%;
    left: 50%;
    transform: translateX(-50%); */

    max-width: 90%;

    color: white;
    line-height: 150%;
    text-align: center;

    font-size: clamp(2rem, 5vw, 3.5rem);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reward-page .title span {
    font-size: clamp(4rem, 5vw, 7rem);
}

.reward-amount {
    position: absolute;
    top: 52.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;

    margin: 0;

    color: white;
    font-size: clamp(5rem, 8vw, 13rem);
    text-shadow: 0px 0px 100px #000;
    text-align: center;

    transform-origin: center;

    line-height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reward-amount span {
    font-size: 0.5em;
    line-height: 100%;
}

.reward-page .reward,
.reward-page .reward-wrapper {
    position: relative;
}

.reward-page .reward-wrapper .reward-image-wrapper {
    position: relative;
}

.reward-page .reward-wrapper .reward-image-wrapper .reward {
    width: 60%;
}

.reward-page .points-notify {
    font-size: clamp(0.5rem, 3vw, 1.5rem);
    line-height: 100%;
    color: white;
    text-align: center;
    padding: 0rem 2rem;
    margin: 0;
}

.reward-page .button-container {
    width: 250px;
    height: 50px;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;

    position: absolute;
    top: 20%;
    left: 0;
}

.reward-page .button-container .button-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.5s ease;
    color: white;
    z-index: 5;
    font-size: 1.5rem;
}

.reward-page .layer {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #007bff;
    top: 0;
    left: 0;
}

.reward-page .layer:nth-child(1) {
    max-width: 0%;
    z-index: 1;
    background-color: #ef19c1;
}

.reward-page .layer:nth-child(2) {
    max-width: 0%;
    background-color: #6974ac;
    z-index: 2;
}

.reward-page .layer:nth-child(3) {
    max-width: 0%;
    background-color: #36458f;
    z-index: 3;
}

.reward-page .layer:nth-child(4) {
    max-width: 0%;
    background-color: #051874;
    transition: background-color 0.1s ease;
    color: white;
    z-index: 4;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.5rem;

    color: rgba(255, 255, 255, 0);
}

.reward-page .layer:nth-child(4):hover {
    background-color: #005acb;
    transition: background-color 0.1s ease;
}

.button-container.thanks-btn {
    position: absolute;
    top: 80%;
    left: auto;
    right: 0;
    transform: scaleX(-1);

    width: 400px;
    height: 70px;
}

.button-container.thanks-btn .button-text {
    transform: translate(-50%, -50%) scaleX(-1);
}

.button-container.thanks-btn .layer {
    cursor: default;
}

.button-container.thanks-btn .layer:nth-child(4):hover {
    cursor: default;
    background-color: #051874;
}

@media screen and (max-width: 1440px) {
    .reward-page .button-container {
        width: 200px;
        height: 40px;
    }

    .reward-page .button-container .button-text {
        font-size: 1.2rem;
    }

    .button-container.thanks-btn {
        width: 300px;
        height: 60px;
    }
}

@media screen and (max-width: 1024px) {
    .reward-page .button-container {
        width: 300px;
        height: 60px;
    }

    .reward-page .button-container .button-text {
        font-size: 1.5rem;
    }

    .button-container.thanks-btn {
        width: 250px;
        height: 50px;
    }
}

@media screen and (max-width: 912px) {
    .reward-page {
        justify-content: flex-start;
    }

    .reward-page .button-container {
        width: 300px;
        height: 60px;
    }

    .reward-page .title {
        top: 20%;
    }

    .reward-page .button-container .button-text {
        font-size: 1.5rem;
    }

    .button-container.thanks-btn {
        width: 200px;
        height: 40px;
    }
}

@media screen and (max-width: 375px) {
    .reward-page .button-container {
        width: 70%;
        height: 40px;
    }
}

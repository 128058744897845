.survey-page {
    width: 100%;
    max-height: 90%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;

    font-family: 'NeueHaasUnicaPro', sans-serif;
    font-weight: 700;
}

.survey-page .survey-page__title {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;

    margin: 0;

    text-align: center;
    color: white;
    font-family: 'NeueHaasUnicaPro', sans-serif;
    font-weight: 700;
    /* font-size: 4rem; */
    font-size: clamp(2rem, 4vw, 4rem);
    font-weight: 700;
    line-height: 110%;
}

.survey-page .survey-page__title span {
    font-weight: 300;
}

.survey-page {
    position: relative;
    width: 100%;
    height: 100%;
}

.survey-page canvas {
    filter: blur(2px);
    z-index: 0;
}

/* LAYERED BUTTON */

.survey-page .survey-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    height: 40%;
}

.survey-page .button-container {
    position: relative;
    width: 700px;
    height: 100%;
    max-height: 100px;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
}

.survey-page .button-container.submit-button {
    width: 400px;
    height: 100px;
}

.survey-page .button-container .button-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.5s ease;
    color: white;
    z-index: 5;
    font-size: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.survey-page .button-container .button-text .button-text-content {
    width: 400px;
    height: 80px;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
}

.survey-page .button-container .button-text .arrow-right {
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
}

/* .survey-page .button-container .button-text .arrow-right .arrow-right-img {
    position: absolute;
    top: 50%;
    left: 110%;
    transform: translate(-50%, -50%);
} */

.survey-page .button-container .layer {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 100%;
    background-color: #007bff;
    top: 0;
    left: 0;
}

.survey-page .button-container .layer:nth-child(1) {
    max-width: 0%;
    z-index: 1;
    background-color: #ef19c1;
}

.survey-page .button-container .layer:nth-child(2) {
    max-width: 0%;
    background-color: #6974ac;
    z-index: 2;
}

.survey-page .button-container .layer:nth-child(3) {
    max-width: 0%;
    background-color: #36458f;
    z-index: 3;
}

.survey-page .button-container .layer:nth-child(4) {
    max-width: 0%;
    background-color: #051874;
    transition: background-color 0.1s ease;
    color: white;
    z-index: 4;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.5rem;

    color: rgba(255, 255, 255, 0);
}

.survey-page .button-container.selected .layer:nth-child(4) {
    cursor: pointer;
    background-color: #005acb;
}

.survey-page .button-container.submit-button .layer:nth-child(4) {
    cursor: progress !important;
    background-color: #5679a7;
}

.survey-page .button-container.submit-button.selected-option .layer:nth-child(4) {
    cursor: pointer;
    background-color: #051874;
}

.survey-page
    .button-container.submit-button.selected-option
    .button-text
    .button-text-content:hover {
    background-color: #005acb;
    transition: background-color 0.1s ease;
}

.survey-page .layer:nth-child(4):hover {
    background-color: #005acb;
    transition: background-color 0.1s ease;
}

/*-----------------------------------------------------------------------*/

@media screen and (max-width: 1024px) {
    .layer:nth-child(4):hover {
        background-color: #005acb;
    }
}

@media screen and (max-width: 768px) {
    .survey-page .survey-buttons {
        width: 90%;
    }

    .survey-page .survey-buttons .button-container {
        width: 100%;
    }
}

@media screen and (max-width: 540px) {
    .survey-page {
        gap: 2rem;
        justify-content: flex-start;
    }

    .survey-page .survey-buttons {
        gap: 1rem;
    }

    .survey-page .survey-page__title {
        /* font-size: 2.5rem; */
        gap: 1.75rem;

        margin-top: 1rem;
    }

    .survey-page .button-container {
        width: 380px;
        height: 55px;
    }

    .survey-page .button-container .button-text {
        font-size: 1.5rem;

        height: 55px;
        width: 100%;
    }

    .survey-page .button-container.submit-button .button-text {
        height: 55px;
    }

    .survey-page .button-container .button-text .button-text-content {
        width: 380px;
        height: 55px;
    }

    .survey-page .button-container.submit-button .button-text .arrow-right {
        scale: 0.8;
        top: 50%;
    }

    .survey-page .button-container.submit-button {
        width: 250px;
        height: 55px;
    }

    .survey-page .little-santa {
        left: 47.5%;
    }
}

@media screen and (max-width: 430px) {
    .survey-page .button-container {
        width: 320px;
    }

    .survey-page .button-container .button-text {
        max-width: 320px;
    }

    .survey-page .button-container.submit-button {
        width: 230px;
    }

    .survey-page .button-container.submit-button .button-text .arrow-right {
        scale: 0.75;
        top: 47.5%;
    }
}

@media screen and (max-width: 375px) {
    .survey-page .button-container {
        width: 300px;
    }

    .survey-page .button-container .button-text {
        max-width: 300px;
    }

    .survey-page .button-container.submit-button {
        width: 210px;
    }

    .survey-page .button-container.submit-button .button-text .arrow-right {
        scale: 0.7;
        top: 45%;
    }
}

@media screen and (max-width: 320px) {
    .survey-page .button-container {
        width: 260px;
    }

    .survey-page .button-container.submit-button {
        width: 190px;
    }

    .survey-page .button-container .button-text {
        max-width: 260px;
    }

    .survey-page .button-container.submit-button .button-text .arrow-right {
        scale: 0.7;
        top: 45%;
    }
}

@media screen and (max-height: 900px) {
    .survey-buttons {
        gap: 0.75rem !important;
    }
}

@media screen and (max-height: 700px) {
    .survey-page .survey-page__title {
        /* font-size: 3rem; */
    }

    .survey-page .survey-buttons {
        gap: 0.5rem !important;
    }

    .survey-page .button-container {
        max-width: 500px;
    }

    .survey-page .button-container .button-text {
        width: 500px;
    }

    .survey-page .button-container .button-text {
        font-size: 1.5rem !important;
    }
}

@media screen and (max-width: 280px) {
    .survey-page .survey-page__title {
        font-size: 1.75rem !important;
    }

    .survey-page .button-container {
        width: 200px;
        height: 40px;
    }

    .survey-page .button-container .button-text {
        max-width: 230px;
        height: 40px;
    }

    .survey-page .button-container .button-text {
        font-size: 1.25rem !important;
    }

    /* .survey-page .button-container.submit-button .button-text .arrow-right {
        scale: 0.5;
        top: 43.5%;
    }

    .survey-page .button-container.submit-button {
        height: 40px;
        width: 140px;
    } */
}

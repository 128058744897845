body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}
#widgetRoot {
  /* width: 50%; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-con {
  position: relative;
  width: 100%;
  max-width: 450px;
  max-height: calc(450px / 8);
  /*padding-bottom: 30%;*/
  height: 12vw;
  margin-bottom: 15px;
  z-index: 0;
}

.con1 {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 9px;
  max-height: 1.8vw;
}

.child1,
.child2 {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.child1 {
  width: 100%;
}

.child2 {
  position: absolute;
  top: 0;
  /* transition: all 7s ease; */
  width: 0;
}

.con2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.animate {
  animation: widthAnimation 5s ease-in-out forwards; /* Match the duration with the setTimeout in JavaScript */
}

@keyframes widthAnimation {
  from {
    width: var(--initial-width); /* Initial width */
  }
  to {
    width: var(--final-width); /* Final width */
  }
}

.icon1,
.icon2,
.icon3 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 12%;
  height: auto;
  background-color: white;
  display: flex;
  padding: 0 1%;
}

.icon1 {
  left: calc(22.2575% - 5.885%);
}

.icon2 {
  left: calc(57.7725% - 6.08%);
}

.icon3 {
  left: calc(94% - 8%);
}

.open-question-page {
    width: 100%;
    height: 100%;

    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 7rem;

    overflow: hidden;
}

.open-question-page h1 {
    color: white;
    font-family: 'NeueHaasUnicaPro', sans-serif;
    font-weight: 600;
    /* font-size: 3.5rem; */
    font-size: clamp(1.5rem, 4vw, 3.5rem);
    line-height: 110%;
    text-align: center;

    max-width: 90%;
    margin: 0;
    margin-top: 3rem;
}

/* .open-question-page .button-container {
    position: relative;
    width: 700px;
    height: 80px;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
}

.open-question-page .button-container.submit-button {
    width: 500px;
}

.open-question-page .button-container .button-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.5s ease;
    color: white;
    z-index: 5;
    font-size: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.open-question-page .button-container .button-text .button-text-content {
    width: 500px;
    height: 80px;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
}

.open-question-page .button-container .button-text .arrow-right {
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
}

.open-question-page .button-container .layer {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 100%;
    background-color: #007bff;
    top: 0;
    left: 0;
}

.open-question-page .button-container .layer:nth-child(1) {
    max-width: 0%;
    z-index: 1;
    background-color: #ef19c1;
}

.open-question-page .button-container .layer:nth-child(2) {
    max-width: 0%;
    background-color: #6974ac;
    z-index: 2;
}

.open-question-page .button-container .layer:nth-child(3) {
    max-width: 0%;
    background-color: #36458f;
    z-index: 3;
}

.open-question-page .button-container .layer:nth-child(4) {
    max-width: 0%;
    background-color: #051874;
    transition: background-color 0.1s ease;
    color: white;
    z-index: 4;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.5rem;

    color: rgba(255, 255, 255, 0);
}

.open-question-page .button-container.selected .layer:nth-child(4) {
    cursor: pointer;
    background-color: #005acb;
}

.open-question-page .button-container.submit-button .layer:nth-child(4) {
    cursor: progress !important;
    background-color: #5679a7;
}

.open-question-page .button-container.submit-button.selected-option .layer:nth-child(4) {
    cursor: pointer;
    background-color: #051874;
}

.open-question-page
    .button-container.submit-button.selected-option
    .button-text
    .button-text-content:hover {
    background-color: #005acb;
    transition: background-color 0.1s ease;
} */

.open-question-page .layer:nth-child(4):hover {
    background-color: #005acb;
    transition: background-color 0.1s ease;
}

.open-question-page .little-santa {
    position: absolute;
    bottom: -40%;
    left: 70%;
    transform: translate(-50%, -50%);
    z-index: 6;
}

.open-question-page .message-cloud {
    position: absolute;
    bottom: 10%;
    opacity: 1;
    left: 82.5%;
    transform: translate(-50%, -50%);
    z-index: 6;
}

@media screen and (max-width: 1024px) {
    .open-question-page {
        justify-content: flex-start;
        gap: 3rem;
    }

    /* .open-question-page .button-container.submit-button {
        width: 300px;
        height: 80px;
    }

    .open-question-page .button-container .button-text {
        /* font-size: 1.5rem;

        width: 300px;
        height: 80px;
    } */
}

@media screen and (max-width: 540px) {
    /* .open-question-page .button-container.submit-button {
        width: 250px;
        height: 60px;
    }

    .open-question-page .button-container .button-text {
        font-size: 1.5rem;

        width: 250px;
        height: 60px;
    }

    .open-question-page .button-container.submit-button .button-text-content {
        width: 250px;
        height: 60px;
    }

    .open-question-page .button-container.submit-button .arrow-right {
        scale: 0.6;
        top: 42.5%;
    } */
}

@media screen and (max-width: 430px) {
    /* .open-question-page .button-container.submit-button {
        width: 200px;
        height: 55px;
    }

    .open-question-page .button-container .button-text {
        font-size: 1.5rem;

        width: 200px;
        height: 55px;
    }

    .open-question-page .button-container.submit-button .button-text-content {
        height: 60px;
        width: 200px;
    }

    .open-question-page .button-container.submit-button .button-text .arrow-right {
        scale: 0.75;
        top: 47.5%;
    } */
}

@media screen and (max-width: 375px) {
    /* .open-question-page .button-container.submit-button {
        width: 180px;
        height: 50px;
    }

    .open-question-page .button-container .button-text {
        font-size: 1.35rem;

        width: 180px;
        height: 50px;
    }

    .open-question-page .button-container.submit-button .button-text-content {
        height: 50px;
        width: 300px;
    }

    .open-question-page .button-container.submit-button .button-text .arrow-right {
        scale: 0.7;
        top: 45%;
    } */
}

/* SANTA */

.santa-hands {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.santa-head {
    position: absolute;
    bottom: -20%;
    left: 54%;
    transform: translateX(-50%) rotate(0deg);
    transform-origin: center;
}

/* SECRET GIFT */

.secret-gift {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transform-origin: center;
}

/* SHOE */

.shoe {
    position: absolute;
    top: 55.7%;
    left: 49%;
    transform: translate(-50%, -50%);
}

/* FAMOUS PLACES BG */

.famous-places {
    position: relative;
    cursor: pointer;
}

.reward {
    filter: blur(2px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
}

@media screen and (max-width: 1440px) {
    .santa-head,
    .santa-hands,
    .secret-gift,
    .shoe {
        width: 70%;
    }
}

@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
    .santa-head,
    .santa-hands {
        width: 90%;
    }

    .shoe {
        top: 53.5%;
    }
}
@media screen and (max-width: 540px) {
    .santa-head,
    .santa-hands {
        width: 100%;
    }

    .shoe {
        top: 52.5%;
    }
}
@media screen and (max-width: 375px) {
}
@media screen and (max-width: 320px) {
}
@media screen and (max-width: 280px) {
}

.datalisted-input-wrapper {
    position: relative;
    width: 70%;
    height: 120px;
    text-align: center;
}

.datalisted-input-wrapper .layer {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
}

.datalisted-input-wrapper .layer:nth-child(1) {
    max-width: 0%;
    z-index: 1;
    background-color: #ef19c1;
}

.datalisted-input-wrapper .layer:nth-child(2) {
    max-width: 0%;
    background-color: #6974ac;
    z-index: 2;
}

.datalisted-input-wrapper .layer:nth-child(3) {
    max-width: 0%;
    background-color: #36458f;
    z-index: 3;
}

.datalisted-input-wrapper .layer:nth-child(4) {
    max-width: 0%;
    background-color: #051874;
    transition: background-color 0.1s ease;
    z-index: 4;

    border: none;

    color: white;
    /* font-size: 2rem; */
    font-size: clamp(1.25rem, 4vw, 2rem);
    font-family: 'NeueHaasUnicaPro', sans-serif;
    font-weight: 400;

    padding: 1.5rem;

    box-sizing: border-box;

    /* white-space: normal;
    text-align: justify;
    -moz-text-align-last: center; /* Firefox 12+ */
    /* text-align-last: center;
    vertical-align: middle; */

    text-align: center;
}

.datalisted-input-wrapper .layer:nth-child(4):focus {
    outline: none;
}

.datalisted-input-wrapper .layer:nth-child(4)::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 1024px) {
    .datalisted-input-wrapper {
        width: 80%;
    }
}

@media screen and (max-width: 540px) {
    .datalisted-input-wrapper {
        height: 10%;
    }
}

html {
    font-size: 12px;
    background-color: white;

    font-family: 'Helvetica', serif;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;

    /* identical to box height */

    /* Blue/100 */

    --color-1: #001b71;
    --color-2: #b6d2ff;
    color: #001b71;
    overflow-x: hidden;
    position: relative;
}
.MuiCircularProgress-colorPrimary {
    color: var(--color-1) !important;
}
body {
    overflow-x: hidden;
    position: relative;
    max-width: 100%;
}
:root {
    --footer-padding: 0.8rem;
}

.flex {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.f1 {
    flex: 1;
    width: 100%;
}

root,
body,
html,
.App {
    height: 100vh;
    height: 100dvh;
    margin: 0;
    background-color: #04104a;
    overflow: hidden;
}

.App.scrolled {
    overflow-y: scroll;
}

.App {
    display: flex;
    flex-direction: column;
}

.main {
    position: relative;
    display: flex;
    flex: 1;
    /*padding:20px 10px;*/
    justify-content: center;
}

button {
    background-color: transparent;
    border: none;
    border-radius: 0;
    cursor: pointer;
}

a {
    color: inherit;
}

.ScratchCard__Canvas {
    left: 0;
}

.react-card-flip {
    width: 100%;
}

.swipe {
    user-select: none;
    cursor: move;
    cursor: grab;
}

.swipe:active {
    cursor: grabbing;
}

.dNone {
    display: none;
}

.star {
    transition-property: background-color, border-color, color, fill, stroke;
}

.star2 {
    color: #007bff;
    transition-property: background-color, border-color, color, fill, stroke;
}

.landing-page {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    font-family: 'NeueHaasUnicaPro', sans-serif;
    font-weight: 700;
}

canvas {
    filter: blur(2px);
    z-index: 0;
}

.landing-page .landing-page__title-wrapper {
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
}

.landing-page .landing-page__title-wrapper img {
    scale: 0.8;
}

.landing-page .landing-page__title-wrapper h1 {
    color: white;
    font-size: 3rem;
    border-radius: 0 0 50% 50%;
    letter-spacing: 150%;
    margin: -2rem auto 0 auto;

    width: fit-content;
}

.landing-page .landing-page__buttons {
    position: absolute;
    right: 0;
    top: 60%;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 1rem;
}

.landing-page .button-container {
    /* position: absolute;
    top: 74%;
    right: 0; */
    width: 320px;
    height: 100px;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    transform: scaleX(-1);
}

.landing-page .button-container.estimated {
    font-family: 'NeueHaasUnicaPro', sans-serif;
    font-weight: 700;
    top: 60%;
    width: 410px;
}

.landing-page .button-container.estimated .button-text {
    font-family: 'NeueHaasUnicaPro', sans-serif !important;
    font-weight: 700;
    width: 100%;
}

.landing-page .button-container .button-text .arrow-right .arrow-right-img {
    position: absolute;
    top: 50%;
    left: 110%;
    transform: translate(-50%, -50%);
}

.landing-page .button-container .button-text {
    text-align: center;
    min-width: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scaleX(-1);
    opacity: 0;
    transition: opacity 0.5s ease;
    color: white;
    z-index: 5;
    font-size: 2.5rem;
}

.landing-page .button-container .layer {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #007bff;
    top: 0;
    left: 0;
    transform: scaleX(-1);
}

.landing-page .button-container .layer:nth-child(1) {
    max-width: 0%;
    z-index: 1;
    background-color: #ef19c1;
}

.landing-page .button-container .layer:nth-child(2) {
    max-width: 0%;
    background-color: #6974ac;
    z-index: 2;
}

.landing-page .button-container .layer:nth-child(3) {
    max-width: 0%;
    background-color: #36458f;
    z-index: 3;
}

.landing-page .button-container .layer:nth-child(4) {
    max-width: 0%;
    background-color: #051874;
    transition: background-color 0.1s ease;
    color: white;
    z-index: 4;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.5rem;

    color: rgba(255, 255, 255, 0);
}

.landing-page .layer:nth-child(4):hover {
    background-color: #005acb;
    transition: background-color 0.1s ease;
}

.arrow-right {
    padding-left: 0;
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
    .landing-page .landing-page__title-wrapper {
        top: 70%;
    }

    .landing-page__title-wrapper img {
        scale: 0.7;
        transform: translateY(-40%);
    }

    .landing-page__title-wrapper h1 {
        position: absolute;
        top: 65%;
        left: 50%;
        margin: 0;
        transform: translate(-50%, -50%);
    }

    .landing-page .landing-page__buttons {
        top: 65%;
    }

    .landing-page .button-container {
        width: 270px;
        height: 55px;
    }

    .landing-page .button-container.estimated {
        font-family: 'NeueHaasUnicaPro', sans-serif;
        font-weight: 700;
        top: 64%;
        width: 340px;
    }

    .landing-page .button-container .button-text {
        font-size: 2rem;
    }

    .landing-page .button-container.estimated .button-text {
        font-size: 1.75rem;
        min-width: fit-content;
    }
}

@media screen and (max-height: 800px) {
    .landing-page .landing-page__title-wrapper {
        top: 55%;
    }

    .landing-page__title-wrapper .famous-places {
        scale: 0.5 !important;
        transform: translateY(-35%) !important;
    }

    .landing-page__title-wrapper h1 {
        position: absolute;
        top: 65%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
    }

    .landing-page .landing-page__buttons {
        top: 75%;
    }

    .landing-page .button-container {
        width: 370px;
        height: 70px;
    }

    .landing-page .button-container.estimated {
        font-family: 'NeueHaasUnicaPro', sans-serif;
        font-weight: 700;
        top: 64%;
        width: 450px;
    }

    .landing-page .button-container .button-text {
        font-size: 2rem;
    }

    .landing-page .button-container.estimated .button-text {
        font-size: 1.75rem;
        min-width: fit-content;
    }
}

@media screen and (max-height: 700px) {
    .landing-page .landing-page__title-wrapper {
        top: 50%;
    }

    .landing-page__title-wrapper .famous-places {
        scale: 0.4 !important;
        transform: translateY(-40%) !important;
    }

    .landing-page__title-wrapper h1 {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
    }

    .landing-page .landing-page__buttons {
        top: 75%;
    }

    .landing-page .button-container {
        width: 250px !important;
        height: 40px !important;
    }

    .landing-page .button-container.estimated {
        font-family: 'NeueHaasUnicaPro', sans-serif;
        font-weight: 700;
        top: 64%;
        width: 300px !important;
    }

    .landing-page .button-container .button-text {
        font-size: 2rem;
    }

    .landing-page .button-container.estimated .button-text {
        font-size: 1.75rem;
        min-width: fit-content;
    }
}

@media screen and (min-width: 1024px) and (max-height: 800px) {
    .landing-page .famous-places {
        transform: translateY(-60%) !important;
    }

    .landing-page .landing-page__title-wrapper {
        top: 65%;
    }
}

@media screen and (max-width: 1024px) {
    .landing-page .landing-page__title-wrapper {
        top: 55%;
    }

    .landing-page__title-wrapper img {
        scale: 0.6 !important;
        transform: translateY(-40%);
    }

    .landing-page__title-wrapper h1 {
        position: absolute;
        top: 65%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
    }

    .landing-page .landing-page__buttons {
        top: 75%;
    }

    .landing-page .button-container {
        width: 370px;
        height: 70px;
    }

    .landing-page .button-container.estimated {
        font-family: 'NeueHaasUnicaPro', sans-serif;
        font-weight: 700;
        top: 64%;
        width: 450px;
    }

    .landing-page .button-container .button-text {
        font-size: 2rem;
    }

    .landing-page .button-container.estimated .button-text {
        font-size: 1.75rem;
        min-width: fit-content;
    }
}

@media screen and (min-width: 540px) and (max-width: 768px) {
    .landing-page .landing-page__title-wrapper {
        top: 50%;
    }

    .landing-page .landing-page__title-wrapper img {
        scale: 0.5 !important;
    }

    .landing-page .button-container {
        width: 270px;
        height: 55px;
    }

    .landing-page .button-container.estimated {
        width: 350px;
        height: 55px;
    }
}

@media screen and (min-width: 430px) and (max-width: 540px) {
    .landing-page .landing-page__title-wrapper {
        top: 50%;
    }

    .landing-page__title-wrapper img {
        scale: 0.5 !important;
        transform: translateY(-40%) !important;
    }

    .landing-page__title-wrapper h1 {
        position: absolute;
        top: 60%;
        margin: 0;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .landing-page .landing-page__buttons {
        top: 65%;
    }

    .landing-page .button-container {
        width: 270px !important;
        height: 55px !important;
    }

    .landing-page .button-container.estimated {
        font-family: 'NeueHaasUnicaPro', sans-serif;
        font-weight: 700;
        top: 64%;
        width: 340px !important;
    }

    .landing-page .button-container .button-text {
        font-size: 2rem;
    }

    .landing-page .button-container.estimated .button-text {
        font-size: 1.75rem;
        min-width: fit-content;
    }
}

@media screen and (max-width: 375px) and (max-height: 800px) {
    .landing-page .famous-places {
        transform: translateY(-60%) !important;
    }
}

@media screen and (min-width: 375px) and (max-width: 430px) {
    .landing-page__title-wrapper .famous-places {
        scale: 0.4 !important;
        transform: translateY(-35%) !important;
    }

    .landing-page__title-wrapper h1 {
        font-size: 2.5rem !important;
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .landing-page .button-container {
        width: 270px;
        height: 55px;
    }

    .landing-page .button-container.estimated {
        font-family: 'NeueHaasUnicaPro', sans-serif;
        font-weight: 700;
        top: 66%;
        width: 340px;
    }

    .landing-page .button-container .button-text {
        font-size: 2rem;
    }

    .landing-page .button-container.estimated .button-text {
        font-size: 1.75rem;
        min-width: fit-content;
    }
}

@media screen and (min-width: 320px) and (max-width: 375px) {
    .landing-page .landing-page__title-wrapper img {
        scale: 0.35 !important;
        transform: translateY(-60%);
    }

    .landing-page .landing-page__title-wrapper h1 {
        top: 55%;
        font-size: 2rem !important;
    }

    .landing-page .landing-page__buttons {
        top: 65%;
    }

    .landing-page .button-container {
        width: 230px;
        height: 55px;
    }

    .landing-page .button-container.estimated {
        font-family: 'NeueHaasUnicaPro', sans-serif;
        font-weight: 700;
        width: 310px;
    }

    .landing-page .button-container .button-text {
        font-size: 1.75rem;
    }

    .landing-page .button-container.estimated .button-text {
        font-size: 1.75rem;
        min-width: fit-content;
    }
}

@media screen and (min-width: 280px) and (max-width: 320px) {
    .landing-page .landing-page__title-wrapper img {
        scale: 0.3 !important;
        transform: translateY(-60%);
    }

    .landing-page .landing-page__title-wrapper h1 {
        top: 55%;
        font-size: 1.75rem !important;
    }

    .landing-page .landing-page__buttons {
        top: 65%;
    }

    .landing-page .button-container {
        width: 210px;
        height: 55px;
    }

    .landing-page .button-container.estimated {
        font-family: 'NeueHaasUnicaPro', sans-serif;
        font-weight: 700;
        width: 270px;
    }

    .landing-page .button-container .button-text {
        font-size: 1.75rem;
    }

    .landing-page .button-container.estimated .button-text {
        font-size: 1.5rem;
        min-width: fit-content;
    }
}

@media screen and (max-width: 280px) {
    .landing-page .landing-page__title-wrapper img {
        scale: 0.25 !important;
        transform: translateY(-60%);
    }

    .landing-page .landing-page__title-wrapper h1 {
        top: 55%;
        font-size: 1.75rem !important;
    }

    .landing-page .landing-page__buttons {
        top: 65%;
    }

    .landing-page .button-container {
        width: 190px;
        height: 55px;
    }

    .landing-page .button-container.estimated {
        font-family: 'NeueHaasUnicaPro', sans-serif;
        font-weight: 700;
        width: 250px;
    }

    .landing-page .button-container .button-text {
        font-size: 1.5rem;
    }

    .landing-page .button-container.estimated .button-text {
        font-size: 1.35rem;
        min-width: fit-content;
    }
}

@media screen and (max-width: 280px) and (max-height: 800px) {
    .landing-page .button-container {
        width: 190px !important;
        height: 55px;
    }

    .landing-page .button-container.estimated {
        width: 270px !important;
        height: 55px;
    }
}

@media screen and (max-height: 650px) {
    .landing-page .famous-places {
        scale: 0.3 !important;
    }
}
